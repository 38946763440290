import { template as template_4f8a7796c0cb450e9bfd76ec682f3115 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4f8a7796c0cb450e9bfd76ec682f3115(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
