import { template as template_2447ebdcabad4191af951db03252a5e5 } from "@ember/template-compiler";
const FKText = template_2447ebdcabad4191af951db03252a5e5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
