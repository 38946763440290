import { template as template_e72b9453954a4aeaa0096d3c1a91367e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_e72b9453954a4aeaa0096d3c1a91367e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
