import { template as template_b70236a254b84beab1c5ac3a39f3d3de } from "@ember/template-compiler";
const FKControlMenuContainer = template_b70236a254b84beab1c5ac3a39f3d3de(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
